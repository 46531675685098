import React from "react"
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"
import keywords from "../../constants/keywords"
import AutomationServices from "../../components/AutomationServices"
import ImageTitleDescriptionBullets from "../../components/ImageTitleDescriptionBullets"
import DigitalSignageImage from "../../assets/images/AutomationServicesImages/Airport-Signage-Screens.jpg"
import RoboticsImage from "../../assets/images/AutomationServicesImages/Person-Grabbing-Drink-From-Robot.jpg"
import KiosksImage from "../../assets/images/AutomationServicesImages/Person-Using-Kiosk-System.jpg"
import PointofSaleImage from "../../assets/images/AutomationServicesImages/Woman-Shopping-In-Person-POS.jpg"
import RoomAutomationImage from "../../assets/images/AutomationServicesImages/Office-Automation-Screens-Blinds.jpg"
import IoTDevelopmentImage from "../../assets/images/AutomationServicesImages/Person-Hands-Working-On-Hardware-Board.jpg"

// const LoadableParallax = loadable(() => import('../components/parallax/homeParallax'))

const AutomationServicesPage = () => {

  // let keywords = keywords.home.keywords
  // console.log(keywords.home.keywords)
  return (
    <>

      <Layout black={false}>

        <SEO
          title={"Automation Services | Differnt Systems"}
          description={"Scalable Automation Services: Kiosk Stations, Digital Signage, Robotics, Point of Sale, Room Automation, & IoT Development. Tailored solutions for efficient operations."}
          customKeywords={keywords.home.keywords}
        />
        <div className="offcanvas-wrap">
          <AutomationServices />
          <ImageTitleDescriptionBullets
            id='kiosks'
            featureImage={KiosksImage}
            title="Kiosk Stations"
            description="Offer your clients an automated station that scales alongside their operational and budgetary needs. With flexible integration options across diverse hardware and cloud platforms, maintenance and updates can be handled remotely, eliminating the need for on-site technical staff."
            bullet1="Reduce Friction in High-Touch Environments"
            bullet2="Integrate Card Readers, Printers, Cameras, and More"
            bullet3="Customize Software or add AI to Match Desired Experience"
            subTitle=""

          />
          <ImageTitleDescriptionBullets
            id='signage'
            featureImage={DigitalSignageImage}
            title="Digital Signage"
            description="Customize signage to meet your precise marketing or announcement goals. Whether you require a resilient system that remains active during emergencies, one offering additional graphic flexibility and creativity, or enhanced engagement tracking capabilities, own your system without per-display subscription costs."
            bullet1="Dynamically Change Content Based on Audience"
            bullet2="Provide Flexible, Real-Time Updates to Guests"
            bullet3="Generate Impression Reports for Advertising Partners"
            subTitle="Popular"
            flip={true}
          />
          <ImageTitleDescriptionBullets
            id='robotics'
            featureImage={RoboticsImage}
            title="Robotics"
            description="Amid rising labor shortages and increasing wages, it's crucial to deploy your key staff where they can make the greatest impact. By integrating robotics into tasks, you can empower your team to operate more efficiently while preserving the personal touch for customers. With a variety of robotic solutions and a team of certified integration specialists, we'll tailor every aspect of the experience to maximize your investment."
            bullet1="Augment Staffing Capacity & Efficiency"
            bullet2="Reduce Room Service Delivery Wait Times"
            bullet3="Activate Marketing Initiatives That Can Move"
            subTitle="Trending"
            flip={false}
          />
          <ImageTitleDescriptionBullets
            id='pos'
            featureImage={PointofSaleImage}
            title="POS & Cashless RFID"
            description="Boost Point of Sale revenue by reducing fees, adding new payment capabilities, or integrating advancements in loyalty programs. Whether utilizing existing hardware or deploying new systems, we collaborate with your team to seamlessly integrate these opportunities and enhance your cash flow."
            bullet1="Enable Frictionless Payments through RFID Technology"
            bullet2="Monitor Guest Spending Patterns and Preferences"
            bullet3="Add Mobile Ordering or Self-Service Kiosk Capabilities"
            subTitle=""
            flip={true}
          />
          <ImageTitleDescriptionBullets
            id='room-automation'
            featureImage={RoomAutomationImage}
            title="Room Automation"
            description="At the heart of your facility lies the opportunity to automate the intricacies that personalize the space. From blinds, lighting, and audio to conferencing and cleaning, our room automation specialists will enable you to tailor the level of human interaction required for common tasks in your most utilized spaces."
            bullet1="Add Touchscreen Interfaces for Easy Control"
            bullet2="Create an Easy Conferencing BYOD Experience"
            bullet3="Automate Cleaning Routines with Programmable Robots"
            subTitle=""
          />
          <ImageTitleDescriptionBullets
            id='development'
            featureImage={IoTDevelopmentImage}
            title="IoT Development"
            description="When standard off-the-shelf hardware don't meet your project requirements, our team of hardware engineers can develop fully customized solutions, meticulously tailored to your specifications down to the printed circuit board (PCB)."
            bullet1="Create Next-Generation All-In-One Solutions"
            bullet2="Experiment with Early Stage Prototyping"
            bullet3="Optimize Performance with Custom Software Services"
            subTitle=""
            flip={true}
          />
          {/* <LoadableParallax /> */}
        </div>
      </Layout>

    </>
  )
}

export default AutomationServicesPage