import React from "react"
import loadable from '@loadable/component'

import SetsUsApart from "../components/SetsUsApart"
import Showcase from "../components/Showcase"
import Testimonials from "../components/Testimonials"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Hero from "../components/MainHero"
import MainHero from "../components/MainHero"
import keywords from "../constants/keywords"
import LoadableParallax from "../components/parallax/homeParallax"
import NoticeBar from '../components/NoticeBar';

import About from '../components/About';
import Video from '../components/MainVideo';

// const LoadableParallax = loadable(() => import('../components/parallax/homeParallax'))

const IndexPage = () => {

  // let keywords = keywords.home.keywords
  // console.log(keywords.home.keywords)
  return (
    <>

      <Layout black={false}>

        <SEO
          title={"Software, Automation & Cloud Experts | Differnt Systems"}
          description={"Differnt Systems (fmr. F&S Digital) delivers custom software, automation, and cloud solutions. Bridging technology gaps for SMBs to enterprises globally."}
          customKeywords={keywords.home.keywords}
        />
        <div className="offcanvas-wrap">
          <MainHero />
          {/* <NoticeBar /> */}
          <About/>
          <section>
          {/* <Video /> */}
          </section>
          {/* <LoadableParallax /> */}
          <SetsUsApart />
          <Showcase />
          
          {/* cta */}
          {/* <section className="inverted py-15 pt-10 pb-5">
            <div className="container">
              <div className="row g-4 g-xl-6">
                <div className="col-lg-6" data-aos="fade-up">
                  <a href="/services/" className="card h-100 border card-arrow">
                    <div className="card-body">
                      <h4 className="card-title fw-bold fs-4">
                        Explore How We Can<br />Collaborate Together
                      </h4>
                    </div>
                  </a>
                </div>
                <div className="col-lg-6" data-aos="fade-up" data-aos-delay={100}>
                  <a href="/contact/" className="card card-arrow bg-purple inverted overflow-hidden" >
                    <div className="card-body">
                      <h4 className="card-title fw-bold fs-4">
                        Have 15 Minutes? <br /> We'd Love To Chat!
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </Layout>

    </>
  )
}

export default IndexPage
