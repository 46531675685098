import React from 'react';
import { Helmet } from "react-helmet"
import arrowRight from "../../assets/images/button_arrow.png"
import featureImage from "../../assets/images/BuildImages/Woman-checking-her-smartwatch.jpg"
import featureVideo1 from "../../assets/images/feature-video1.jpg"
import featureVideo2 from "../../assets/images/feature-video2.jpg"
import BtnArrow from "../../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../../assets/images/button_arrowbottom.svg"

function BuildSolutionSoftware(props) {
    return (
        <>
            <Helmet>
                {/* <script src="https://fast.wistia.com/embed/medias/7za409l7ze.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script> */}
            </Helmet>
            <section class="inverted mx-xl-3 feature-video">
            <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                           <img src={featureImage} alt='Woman checking her smartwatch'/> 
                        </div>
                        <div className='col-md-6'>
                            <div className='feature-right'>
                                <span className='sub-title'>Software Development Services</span>
                                <h2>Build Your Vision.</h2>
                                <p>Craft the next big app or gain a competitive edge by leveraging cutting-edge languages and frameworks with our award-winning team of engineers, designers, developers, and quality assurance specialists. Your vision will be ready to confidently launch.</p>
                                 <ul className='list-icon'>
                                    <li>Create an Application that Integrates with Hardware</li>
                                    <li>Improve Upon Existing Systems</li>
                                    <li>Replace Legacy Software and Own Your Code</li>
                                    
                                </ul>   
                                <a href='/software-development-services/' className='button-link'>Explore Software Services 
                                <div className="arrow_movement">
                                        <img src={BtnArrow} alt=''/>
                                        <img src={BtnArrowbottom} alt=''/>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                
                </div>
               
            </section>
        </>
    );
}

export default BuildSolutionSoftware;