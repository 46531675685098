import React from 'react';
import { Helmet } from "react-helmet"
import arrowRight from "../assets/images/button_arrow.png"
import BtnArrow from "../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../assets/images/button_arrowbottom.svg"



function OurProcess(props) {
    return (
        <>
            <Helmet>
                {/* <script src="https://fast.wistia.com/embed/medias/7za409l7ze.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script> */}
            </Helmet>
            <section class="inverted mx-xl-3 our-process-section">
            <div className='container'>
                  <div className='process-top'>
                    <span className='sub-title'>What Makes Us<span className="differnt-textLogo-white"></span>?</span>
                    <h2>Evolutionary. Collaborative. Systematic.</h2>
                    <p>Our streamlined process ensures clarity regarding proposed solutions, deliverables, and timelines. In a collaborative environment, you'll work alongside our team of top industry experts, ensuring the seamless and fear-free adoption of advanced technologies. This dedication is the cornerstone of our distinguished reputation.</p>
                    <a href="/contact/" class="button-link">Schedule Consultation
                    <div className="arrow_movement">
                        <img src={BtnArrow} alt=''/>
                        <img src={BtnArrowbottom} alt=''/>
                    </div>
                    </a>
                  </div>
                   <div className='row'>
                        <div className='col-md-6'>
                            <div className='process-box'>
                                <h4><span>01</span> Consultation</h4>
                                <p>We'll begin by getting to know you, your business, and your objectives, taking the time to understand your unique needs and exploring a range of technological possibilities for meaningful collaboration ahead.</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='process-box'>
                                <h4><span>02</span> Exploration</h4>
                                <p>We'll assemble a specialized team to assess the intricacies of your project, ensuring meticulous attention while maintaining strict confidentiality and aligning solutions with your budgetary goals.</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='process-box'>
                                <h4><span>03</span> Proposal</h4>
                                <p>We'll guide you through all facets of the project scope, including features, considerations, architecture, and timelines, ensuring full transparency regarding the deliverables.</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='process-box'>
                                <h4><span>04</span> Kickoff</h4>
                                <p>We invite stakeholders to actively participate in meetings, providing opportunities for dynamic brainstorming, valuable feedback sharing, and hands-on prototyping.</p>
                            </div>
                        </div>
                   </div>
                
                </div>   
            </section>
        </>
    );
}

export default OurProcess;