import React from 'react';
import { Helmet } from "react-helmet"
import arrowRight from "../../assets/images/button_arrow.png"
import featureImage from "../../assets/images/SolveImages/Woman-Stressed-At-Laptop.jpg"
import BtnArrow from "../../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../../assets/images/button_arrowbottom.svg"


function SolveSolutionCloud(props) {
    return (
        <>
            <Helmet>
                {/* <script src="https://fast.wistia.com/embed/medias/7za409l7ze.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script> */}
            </Helmet>
            <section class="inverted mx-xl-3 solution-cloud">
            <div className='container'>
                    <div className='row align-items-center'>
                        
                        <div className='col-md-6'>
                            <div className='feature-right'>
                                <span className='sub-title'>Managed Cloud & Cybersecurity</span>
                                <h2>Hackers or Outages Keeping You Up?</h2>
                                <p>Modern systems reside in the cloud, hosting your business's vital data and critical tools for employees and users. Downtime carries a high cost, data loss poses potential disaster, and cyber breaches erode client trust. Scale and secure your infrastructure with a trusted team.</p>
                                 <ul className='list-icon'>
                                    <li>Choose Where You Want Your Systems to Live</li>
                                    <li>Scale Cost Proportionally to Your Needs</li>
                                    <li>Relieve Internal IT Staff to Focus on Internal Threats</li>
                                    
                                </ul>   
                                <a href='/cloud-services/' className='button-link'>Explore Cloud Services
                                    <div className="arrow_movement">
                                            <img src={BtnArrow} alt=''/>
                                            <img src={BtnArrowbottom} alt=''/>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-6'>
                           <img src={featureImage} alt='Woman Stressed At Laptop'/> 
                        </div>
                    </div>
                
                </div>
               
            </section>
        </>
    );
}

export default SolveSolutionCloud;