import React from 'react';
import { Helmet } from "react-helmet"
import arrowRight from "../../assets/images/button_arrow.png"
import featureImage from "../../assets/images/SolveImages/Engineer-Using-Tablet-Software-Unhappy.jpg"
import featureVideo1 from "../../assets/images/feature-video1.jpg"
import featureVideo2 from "../../assets/images/feature-video2.jpg"
import BtnArrow from "../../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../../assets/images/button_arrowbottom.svg"

function SolveSolutionSoftware(props) {
    return (
        <>
            <Helmet>
                {/* <script src="https://fast.wistia.com/embed/medias/7za409l7ze.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script> */}
            </Helmet>
            <section class="inverted mx-xl-3 feature-video">
            <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                           <img src={featureImage} alt='Engineer using tablet software unhappy'/> 
                        </div>
                        <div className='col-md-6'>
                            <div className='feature-right'>
                                <span className='sub-title'>Software Development Services</span>
                                <h2>Software Overload?</h2>
                                <p>Managing multiple or poorly implemented software applications in your business can be costly, challenging, and constraining. It can lengthen employee onboarding time and increase the risk of catastrophe. Regain control by tailoring a solution precisely to meet your business needs.</p>
                                 <ul className='list-icon'>
                                    <li>Connect Multiple Software Applications Together</li>
                                    <li>Craft an Application That Fits Your Exact Workflow</li>
                                    <li>Extend the Capabilities of Existing Software</li>
                                    
                                </ul>   
                                <a href='/software-development-services/' className='button-link'>Explore Software Services 
                                <div className="arrow_movement">
                                        <img src={BtnArrow} alt=''/>
                                        <img src={BtnArrowbottom} alt=''/>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                
                </div>
               
            </section>
        </>
    );
}

export default SolveSolutionSoftware;