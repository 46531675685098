import React from 'react';
import { Helmet } from "react-helmet"
import arrowRight from "../../assets/images/button_arrow.png"
import featureImage from "../../assets/images/SolveImages/Group-Of-People-Walking-Around.jpg"
import BtnArrow from "../../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../../assets/images/button_arrowbottom.svg"


function SolveSolutionNewSignals(props) {
    return (
        <>
            <Helmet>
                {/* <script src="https://fast.wistia.com/embed/medias/7za409l7ze.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script> */}
            </Helmet>
            <section class="inverted mx-xl-3 solution-cloud">
            <div className='container'>
                    <div className='row align-items-center'>
                        
                        <div className='col-md-6'>
                            <div className='feature-right'>
                                <span className='sub-title'>New Signals (Next-Generation Technologies)</span>
                                <h2>Looking to Unlock the Potential of Your Data?</h2>
                                <p>Utilize existing data integrated into your business, whether in physical or digital form. Leverage AI or spatial computing to visualize, extract, and discover new opportunities. Rely on a team of experts driving next-generation research and development initiatives.</p>
                                 <ul className='list-icon'>
                                    <li>Discover Untapped Opportunities in Real-Time</li>
                                    <li>Innovate and Execute With Confidence</li>
                                    <li>Utilize Existing Infrastructure to Gather Data</li>
                                    
                                </ul>   
                                <a href='/new-signals-services/' className='button-link'>Explore New Signals Services
                                    <div className="arrow_movement">
                                            <img src={BtnArrow} alt=''/>
                                            <img src={BtnArrowbottom} alt=''/>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-6'>
                           <img src={featureImage} alt='People walking around a large building'/> 
                        </div>
                    </div>
                
                </div>
               
            </section>
        </>
    );
}

export default SolveSolutionNewSignals;